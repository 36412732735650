import React from 'react'
import { Link } from 'gatsby'

export default (props) => (
	<div className="grid breakpoint-m-grid--3 grid-1 grid--m">
		{props.data.edges.map((index, key) => (
			<div key={ index.node.id } className="magazine-article">
				<Link to={`/post/${ index.node.uid }/`}>
					<div
						className="article-thumbnail width-100 display-block position-relative"
						style={{
							backgroundImage: `url('${ index.node.data.cover.url }')`,
							backgroundSize: 'cover',
							backgroundPosition: 'center center'
						}}>
					</div>
				</Link>

				<Link to={`/post/${ index.node.uid }/`}>
					<span className="article-title">{index.node.data.title.text}</span>
					<span className="article-preview">{index.node.data.preview.text}</span>
				</Link>
				<div className="article-author">
					<div className="article-author--picture">
						{ index.node.data.author.document.map((author) => (
						  <img key={ author.id } src={ author.data.image.url } alt=""/>
						)) }
					</div>
					{ index.node.data.author.document.map((author) => (
					  <span key={ author.id } className="article-author--name">{ author.data.name.text }</span>
					)) }
					<span className="article-author--date">{ index.node.data.publication_date }</span>
				</div>
			</div>
		))}
	</div>
)
import React from 'react'
import { Link } from 'gatsby'

export default (props) => (
	<div>
		{props.data.edges.map((index, key) => (
			<div key={ index.node.id } className="magazine-article magazine-article--full">
				<div className="grid grid--1 breakpoint-m-grid--2-1 breakpoint-m-grid--m marginbottom-xl">
					<div>
						<Link to={`/post/${ index.node.uid }/`}>
							<div 
								className="article-thumbnail width-100 display-block position-relative" 
								style={{ 
									backgroundImage: `url('${ index.node.data.cover.url }')`,
									backgroundSize: 'cover',
									backgroundPosition: 'center center'
								}}>
							</div>
						</Link>
					</div>
					<div>
						{ index.node.data.category.document.map((category) => (
		                  <Link key={ category.id } to={`/category/${ category.uid }/`}>
		                  	<span className={`article-category article-category--${ category.uid }`}>{ category.data.category_name.text }</span>
		                  </Link>
		                )) }
						
						<Link to={`/post/${ index.node.uid }/`}>
							<span className="article-title">{index.node.data.title.text}</span>
							<span className="article-preview">{index.node.data.preview.text}</span>
						</Link>
						<div className="article-author">
							<div className="article-author--picture">
								{ index.node.data.author.document.map((author) => (
								  <img key={ author.id } src={ author.data.image.url } alt=""/>
								)) }
							</div>
							{ index.node.data.author.document.map((author) => (
							  <span key={ author.id } className="article-author--name">{ author.data.name.text }</span>
							)) }
							<span className="article-author--date">{ index.node.data.publication_date }</span>
						</div>
					</div>
				</div>
			</div>
		))}
	</div>
)
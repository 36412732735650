import React from 'react'
import {Link, graphql } from 'gatsby'
import TheLatest from '../components/HomeComponents/TheLatest'
import ArticleGrid from '../components/HomeComponents/ArticleGrid'

const IndexPage = (props) => (
  <div>
    <section>
      <div className="wrapper--s">
        <div className="flex-vcenter width-100">
          <span className="font--r fontsize-l c-black paddingright-m whitespace-nowrap">Latest articles</span>
          <div className="marginbottom-xxl margintop-xxl position-relative display-block width-100 borderwidth-0 borderbottomwidth-1 borderstyle-solid bordercolor-grey--l breakpoint-m--marginbottom-xl breakpoint-m--margintop-xl flexgrow-1">
          </div>
        </div>
        <TheLatest
          data={props.data.theLatest} 
        />
        <ArticleGrid
          data={props.data.threeLatest} 
        />
      </div>
    </section>
    <section>
      <div className="wrapper--s">
        <div className="flex-vcenter width-100">
          <span className="font--r fontsize-l c-black paddingright-m whitespace-nowrap">Tech & Innovations</span>
          <div className="marginbottom-xxl margintop-xxl position-relative display-block width-100 borderwidth-0 borderbottomwidth-1 borderstyle-solid bordercolor-grey--l breakpoint-m--marginbottom-xl breakpoint-m--margintop-xl flexgrow-1">
          </div>
          <Link to="/category/tech-and-innovations/" className="link link-purple link--underline font--r fontsize-s marginleft-m whitespace-nowrap">See all</Link>
        </div>
        <ArticleGrid 
          data={props.data.tech}
        />
      </div>
    </section>
    <section>
      <div className="wrapper--s">
        <div className="flex-vcenter width-100">
          <span className="font--r fontsize-l c-black paddingright-m whitespace-nowrap">Training Room</span>
          <div className="marginbottom-xxl margintop-xxl position-relative display-block width-100 borderwidth-0 borderbottomwidth-1 borderstyle-solid bordercolor-grey--l breakpoint-m--marginbottom-xl breakpoint-m--margintop-xl flexgrow-1">
          </div>
          <Link to="/category/training-room/" className="link link-purple link--underline font--r fontsize-s marginleft-m whitespace-nowrap">See all</Link>
        </div>
        <ArticleGrid 
          data={props.data.training}
        />
      </div>
    </section>
    <section>
      <div className="wrapper--s">
        <div className="flex-vcenter width-100">
          <span className="font--r fontsize-l c-black paddingright-m whitespace-nowrap">News</span>
          <div className="marginbottom-xxl margintop-xxl position-relative display-block width-100 borderwidth-0 borderbottomwidth-1 borderstyle-solid bordercolor-grey--l breakpoint-m--marginbottom-xl breakpoint-m--margintop-xl flexgrow-1">
          </div>
          <Link to="/category/news/" className="link link-purple link--underline font--r fontsize-s marginleft-m whitespace-nowrap">See all</Link>
        </div>
        <ArticleGrid 
          data={props.data.news}
        />
      </div>
    </section>
  </div>
)

export default IndexPage

export const pageQuery = graphql`
fragment articleFields on PrismicArticle {
  slugs
  id
  uid
  data {
    title {
      text
    }
    preview {
      text
    } 
    cover {
      url
    }
    category {
      document {
        id
        data {
          category_name {
            text
          }
        }
        uid
      }
    }
    publication_date(formatString:"MMM D, YYYY")
    author {
      document {
        id
        data {
          name {
            text
          }
          image {
            url
          }
        }
      }
    }
  }
}
query IndexQuery {
  theLatest: allPrismicArticle(
    filter: {
      data: {
        category: {
          document: {
            elemMatch: {
              data: {
                category_name: {
                  text: {
                    ne: "Draft", ne: "Selection"
                  }
                }
              }
            }
          }
        } 
      }
    }
    limit: 1
    sort: {fields: [data___publication_date], order: DESC}
  ){
    edges {
      node {
        ...articleFields
      }
    }
  }
  threeLatest: allPrismicArticle(
    filter: {
      data: {
        category: {
          document: {
            elemMatch: {
              data: {
                category_name: {
                  text: {
                    ne: "Draft", ne: "Selection"
                  }
                }
              }
            }
          }
        } 
      }
    }
    skip: 1
    limit: 3
    sort: {fields: [data___publication_date], order: DESC}
  ){
    edges {
      node {
        ...articleFields
      }
    }
  }
  tech: allPrismicArticle(
    filter: {
      data: {
        category: {
          document: {
            elemMatch: {
              data: {
                category_name: {
                  text: {
                    eq: "Tech & Innovations"
                  }
                }
              }
            }
          }
        }
      }
    }
    limit: 6
    sort: {fields: [data___publication_date], order: DESC}
  ){
    edges {
      node {
        ...articleFields
      }
    }
  }
  news: allPrismicArticle(
    filter: {
      data: {
        category: {
          document: {
            elemMatch: {
              data: {
                category_name: {
                  text: {
                    eq: "News"
                  }
                }
              }
            }
          }
        }
      }
    }
    limit: 6
    sort: {fields: [data___publication_date], order: DESC}
  ){
    edges {
      node {
        ...articleFields
      }
    }
  }
  peer: allPrismicArticle(
    filter: {
      data: {
        category: {
          document: {
            elemMatch: {
              data: {
                category_name: {
                  text: {
                    eq: "Peer Testimonials"
                  }
                }
              }
            }
          }
        }
      }
    }
    limit: 6
    sort: {fields: [data___publication_date], order: DESC}
  ){
    edges {
      node {
        ...articleFields
      }
    }
  }
  training: allPrismicArticle(
    filter: {
      data: {
        category: {
          document: {
            elemMatch: {
              data: {
                category_name: {
                  text: {
                    eq: "Training Room"
                  }
                }
              }
            }
          }
        }
      }
    }
    limit: 6
    sort: {fields: [data___publication_date], order: DESC}
  ){
    edges {
      node {
        ...articleFields
      }
    }
  }
}
`
